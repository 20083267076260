<template>
  <v-container
      id="login"
      class="fill-height justify-center"
      tag="section"
  >
    <Overlay :overlay="globalLoading" :color="'primary'"></Overlay>

    <v-row justify="center" v-show="!globalLoading">
      <v-col cols="12" class="text-center">
        <h1>
          <span class="text-h1">M360API</span> &reg;<br/>
          <span class="text-h2">API Management Platform</span>
        </h1>
      </v-col>
    </v-row>

    <Messages :messages="globalMessages"></Messages>

    <v-row justify="center" v-show="!globalLoading">
      <v-slide-y-transition appear>
        <base-material-card
            color="success"
            light
            max-width="100%"
            width="800"
            class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Login
              </h1>
            </div>
          </template>
          <form @submit="checkForm" novalidate="true">
            <v-card-text class="text-center">

              <v-text-field
                  color="secondary"
                  label="Email..."
                  prepend-icon="mdi-email"
                  v-model="username"
              />

              <v-text-field
                  type="password"
                  class="mb-8"
                  color="secondary"
                  label="Password..."
                  prepend-icon="mdi-lock-outline"
                  v-model="password"
              />

              <pages-btn
                  medium
                  color="mb-2"
                  depressed
                  class="mr-1 v-btn--text success--text"
                  @click="checkForm"
              >
                <v-icon class="mr-1">mdi-fingerprint</v-icon>
                Log In
              </pages-btn>
              <pages-btn
                  medium
                  color="mb-2"
                  depressed
                  class="v-btn--text info--text mr-1"
                  @click="$router.push({name: 'Forgot Password'})"
              >
                <v-icon class="mr-1">mdi-lock</v-icon>
                Forgot Password?
              </pages-btn>

              <pages-btn
                  medium
                  color="mb-2"
                  depressed
                  class="v-btn--text info--text"
                  @click="$router.push({name: 'Resend Link'})"
              >
                <v-icon class="mr-1">mdi-send</v-icon>
                Resend Link?
              </pages-btn>


            </v-card-text>
          </form>
          <div class="my-2"/>

          <div class="or-element text-center grey--text body-1 font-weight-light pa-3 mb-2">
            <span>OR</span>
          </div>
          <v-row class="ma-1 pa-1">
            <v-col sm="12" xs="12" md="12" class="pa-0 ma-0 text-center" v-for="(oneStrategy, name) in strategies"
                   :key="name">
              <pages-btn
                  class="mb-2"
                  medium
                  color=""
                  depressed
                  :class="'v-btn--text ' + oneStrategy.color + '--text'"
                  @click="startLogin3rdParty(name)"
              >
                <v-icon class="mr-1">mdi-{{ oneStrategy.icon }}</v-icon>
                Sign In with {{ name }}
              </pages-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>

  </v-container>
</template>

<script>
import globalMixins from "../../mixins/globalMixins";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Login',

  mixins: [globalMixins],

  components: {
    Overlay: () => import('../../components/OverlayLoading'),
    Messages: () => import('../../components/Messages'),
    PagesBtn: () => import('./components/Btn')
  },

  data: () => ({
    username: '',
    password: '',
    strategies: {
      google: {
        color: 'success',
        icon: 'google-plus',
        key: 'db019d15552f7dc8d64d774967fbdfdeb3bdc0a7d61fe0af1274075fa2235c0faf71aa842b4274acbe8e84085a1702ed4a7ceb08c6a129d57882481d9ff708a6b1e44c317eb034549eb2db013522aa8c'
      },
      // facebook: {
      //   color: 'primary',
      //   icon: 'facebook',
      //   key: '7ee522eaca139a341db9eca7c86120c1231a7b3c094b129976ccc8d1b674cf559b892ea03cc3ce72bbbb83b72e75a73b7d25950433aef6a6bd98ecedd5103d9d42d6021e633c8469d7fc00937e222092'
      // },
      // linkedin: {
      //   color: 'info',
      //   icon: 'linkedin',
      //   key: '364cdfccc75ed2df8a1e94087efeafd5119abdfe7ba9acb92fd9ea71b90f7bef7285edb9de03352373158be8d8b79ebb8c3da95ba950f2f76859790e8922f8a0e52373162c48f5cf6dc386a564f661e4'
      // }
    }
  }),

  async created() {
    this.globalLoading = true;

    if (this.isAuthenticated) {
      if (!this.currentUser) {
        // if this page is requested and the user is already logged in but their info is missing, fetch the info then redirect to main dashboard
        return await this.fetchUserAndAcl().then(() => {
          return this.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
        });
      } else {
        // if this page is requested and the user is already logged in, redirect to main dashboard
        return this.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
      }
    }

    this.globalLoading = false;
  },

  methods: {
    ...mapActions('auth', ['setToken', 'setUserAndACL']),

    async checkForm(e) {
      if (!this.username || !this.validUsernameOrEmail(this.username)) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a valid username or email address.'
        });
        return false;
      }

      if (!this.password) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a password.'
        });
        return false;
      }

      await this.submit();
      e.preventDefault();
    },

    fetchUserAndAcl() {
      const self = this;
      self.globalLoading = true;
      return self.getSendData({
        url: '/user',
        method: 'get'
      }).then(response => {
        self.setUserAndACL(response);
      }).catch(error => {
        self.handleErrorResponse(error);
      }).finally(() => {
        self.globalLoading = false;
      });
    },

    forgotPasswordClicked(e) {
      e.preventDefault();
      this.$router.push({name: 'Forgot Password'});
    },

    /* eslint-disable */
    validUsernameOrEmail: function (email) {
      var re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])|([A-Za-z]+[A-Za-z0-9_-]{2,19}))$/;
      return re.test(email);
    },

    /* eslint-enable */
    async submit() {
      const self = this;
      self.clearMessages();
      this.globalLoading = true;
      return self.executeRequest({
        url: '/token',
        method: 'post',
        headers: {
          env: 'portal'
        },
        params: {
          username: self.username,
          password: self.password
        }
      }).then(async response => {
        if (response.redirect) {
          self.$router.push({path: response.redirect});
        } else {
          self.setToken(response.token);
          self.fetchUserAndAcl()
              .then(() => {
                self.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
              });
        }
      }).catch(error => {
        self.globalLoading = false;
        self.handleErrorResponse(error);
      });
    },

    startLogin3rdParty(strategy) {
      const self = this;

      return self.executeRequest({
        url: `/oauth/${strategy}`,
        method: 'post',
        headers: {
          env: 'portal',
          'x-key': self.strategies[strategy].key
        },
        params: {
          companyName: this.companyName,
          "auto_register_flow": "corsair_saas",
          "return_to_page": window.location.origin + "/social/" + strategy
        }
      }).then(response => {
        window.location.href = response;
      }).catch(error => {
        self.handleErrorResponse(error);
      });
    }
  },
  computed: {
    ...mapGetters({user: 'auth/getUser', token: 'auth/getToken'}),
  }
}
</script>
<style>
.or-element {
  position: relative;
}

.or-element::before {
  display: inline-block;
  border-top: 1px solid rgb(184, 196, 194);
  content: "";
  margin: 0 auto;
  position: absolute;
  height: 0;
  inset: 50% 0 0;
  z-index: 0;
  transform: translateY(-50%);
  width: 300px;
}

.or-element span {
  background-color: #FFFFFF;
  padding: 0 10px;
  position: relative;
  top: 2px;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
}
</style>